@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  font-family: "Poppins" !important;
  font-style: normal;
  scroll-behavior: smooth;
  transition: all 0.5s ease;
}
footer {
  position: relative;
  height: 50vh;
  margin-top: 25vh;
}

.card-product {
  max-width: 350px !important;
}

.foot {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  -webkit-transform: skewY(6deg);
          transform: skewY(6deg);
  -webkit-transform-origin: top right;
          transform-origin: top right;
}

.radius-10 {
  border-radius: 10px !important;
}

.radius-20 {
  border-radius: 20px !important;
}

@media screen and (max-width: 768px) {
}

/* --------------------------------------------------------------- */
/* landing page */
/* ---------------------------------------------------------------- */
.tagline {
  font-size: 4rem;
  text-shadow: 3px 3px 15px black;
}

.z-index-1 {
  z-index: 0;
}

.mt-neg-5 {
  margin-top: -10%;
}

#bg-carousel::before {
  content: " ";
  color: white;
  position: absolute;
  inset: 0 0 0 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.carousel-inner {
  height: 100%;
}

.d3printer {
}

.imgFluid {
  transition: all 0.5s ease;
  height: 100%;
}

.w-md-75 {
  width: 100%;
  transition: all 0.5s ease;
}

.w-lg-75 {
  width: 100%;
  transition: all 0.5s ease;
}

@media screen and (min-width: 768px) {
  .w-md-75 {
    width: 75% !important;
  }
}

@media screen and (max-width: 768px) {
  .tagline {
    font-size: 3rem;
  }
  .d3printer {
    width: 80%;
  }
  .imgFluid {
    height: 70%;
  }
}

@media screen and (max-width: 1024px) {
}

@media screen and (min-width: 1024px) {
  .w-lg-75 {
    width: 84%;
  }
}
/* ------------------------------------------------------------------------------------------------ */
/* 3D printing */
/* ----------------------------------------------------------------------------------------------- */

@media screen and (width: 1024px) {
}

@media screen and (width: 768px) {
}

@media screen and (max-width: 425px) {
}

a {
  text-decoration: none;
  color: #b60000;
}

a:hover {
  text-decoration: none;
  color: #b60000 !important;
}

/* ------------------------------------------------------------------------------------------------ */
/* 1.1 CAROUSEL {START}  */
/* ------------------------------------------------------------------------------------------------ */
.carousel {
  margin: 30px 0;
  background: #ccc;
  position: relative;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.carousel:after {
  content: "";
  position: absolute;
  z-index: -1;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  height: 60px;
  bottom: 0px;
  left: 10px;
  right: 10px;
  border-radius: 100px / 20px;
}
.carousel .item {
  text-align: center;
  overflow: hidden;
  height: 475px;
}
.carousel .item img {
  max-width: 100%;
  margin: 0 auto;
}
.carousel .carousel-control {
  width: 50px;
  height: 50px;
  background: #000;
  margin: auto 0;
  opacity: 0.8;
}
.carousel .carousel-control:hover {
  opacity: 0.9;
}
.carousel .carousel-control i {
  font-size: 41px;
}
.carousel-action {
  padding: 10px 0 30px;
}
.carousel-action .btn {
  min-height: 34px;
  border-radius: 0;
  margin: 3px;
  min-width: 150px;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
}
.carousel-action .btn-primary {
  border-color: #000;
  background: none;
  color: #000;
}
.carousel-action .btn-primary:hover {
  background: #000;
  color: #fff;
}
.carousel-action .btn-success {
  background: #8fc93c;
  border: none;
}
.carousel-action .btn-success:hover {
  background: #87bd35;
}
.carousel-indicators li,
.carousel-indicators li.active {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  margin: 1px 6px;
}
.carousel-indicators li {
  background: transparent;
  border: 1px solid #fff;
}
.carousel-indicators li.active {
  background: #8fc93c;
  border-color: #8fc93c;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

swiper-slide {
    background-position: center;
    background-size: cover;
    width: 250px;
    height: 400px;
    z-index: 1;
  }
  
  @media (min-width: 768px) {
    .swiper-slide {
      width: 250px;
      height: auto;
    }
  }

  @media (min-width: 1024px) {
    .swiper-slide {
      width: 400px;
      height: auto;
    }
  }

  @media (min-width: 1440px) {
    .swiper-slide {
      width: 600px;
      height: auto;
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-pagination-fraction {
    bottom: 0;
  }
  
  .swiper-pagination-bullet {
    width: 25px;
    height: 5px;
    border-radius: 5px;
  }

  .swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 0;
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none!important;
  letter-spacing: 0;
  text-transform: none;
  -webkit-font-feature-settings: ;
          font-feature-settings: ;
  font-variant: initial;
  line-height: 1;
  color: #b60000;
}

:root {
  --swiper-theme-color: #b60000;
}
body{background-color:#121627;color:white}.bg-primary,.btn-primary{background-color:#121627 !important;color:white}.text-primary{color:#121627 !important}.bg-secondary,.btn-secondary{background-color:#b60000 !important;color:white}.btn{border-radius:10px !important;box-shadow:0 0 1rem 0px rgba(0,0,0,0.425) !important}.card{background-color:#192245 !important;border-radius:20px !important;box-shadow:0 0 5rem 0px rgba(0,0,0,0.3) !important}.btn-secondary{border:1px solid #b60000}.text-secondary{color:#b60000 !important}.bg-info,.btn-info{background-color:#192245 !important;color:white}.text-info{color:#192245 !important}.modal-content{background-color:#121627}.btn-link{border:none;background-color:transparent;color:#b60000;text-decoration:none}.smooth-transition{transition:all 0.8s ease}.on-hover:hover{background-color:#b60000 !important}.on-hover-primary:hover{color:#121627 !important}.on-hover-secondary:hover{transition:all 0.3s ease;color:#b60000 !important}.on-hover-light:hover{color:white !important}.on-hover-scale:hover{transition:all 0.3s ease;-webkit-transform:scale(0.97);transform:scale(0.97);box-shadow:0 0 1rem rgba(0,0,0,0.712) !important}.min-vh-75{min-height:75vh}.cursor-pointer{cursor:pointer !important}.btn-whatsapp{color:white;background-color:#25d366}

body{background-color:#121627;color:white}.bg-primary,.btn-primary{background-color:#121627 !important;color:white}.text-primary{color:#121627 !important}.bg-secondary,.btn-secondary{background-color:#b60000 !important;color:white}.btn{border-radius:10px !important;box-shadow:0 0 1rem 0px rgba(0,0,0,0.425) !important}.card{background-color:#192245 !important;border-radius:20px !important;box-shadow:0 0 5rem 0px rgba(0,0,0,0.3) !important}.btn-secondary{border:1px solid #b60000}.text-secondary{color:#b60000 !important}.bg-info,.btn-info{background-color:#192245 !important;color:white}.text-info{color:#192245 !important}.modal-content{background-color:#121627}.btn-link{border:none;background-color:transparent;color:#b60000;text-decoration:none}.smooth-transition{transition:all 0.8s ease}.on-hover:hover{background-color:#b60000 !important}.on-hover-primary:hover{color:#121627 !important}.on-hover-secondary:hover{transition:all 0.3s ease;color:#b60000 !important}.on-hover-light:hover{color:white !important}.on-hover-scale:hover{transition:all 0.3s ease;-webkit-transform:scale(0.97);transform:scale(0.97);box-shadow:0 0 1rem rgba(0,0,0,0.712) !important}.min-vh-75{min-height:75vh}.cursor-pointer{cursor:pointer !important}.btn-whatsapp{color:white;background-color:#25d366}::-webkit-scrollbar{width:10px}::-webkit-scrollbar-track{box-shadow:inset 0 0 5px transparent;border-radius:10px}::-webkit-scrollbar-thumb{background:#b60000;border-radius:10px}::-webkit-scrollbar-thumb:hover{background:#910808}

