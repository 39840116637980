swiper-slide {
    background-position: center;
    background-size: cover;
    width: 250px;
    height: 400px;
    z-index: 1;
  }
  
  @media (min-width: 768px) {
    .swiper-slide {
      width: 250px;
      height: auto;
    }
  }

  @media (min-width: 1024px) {
    .swiper-slide {
      width: 400px;
      height: auto;
    }
  }

  @media (min-width: 1440px) {
    .swiper-slide {
      width: 600px;
      height: auto;
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }

  .swiper-pagination-fraction {
    bottom: 0;
  }
  
  .swiper-pagination-bullet {
    width: 25px;
    height: 5px;
    border-radius: 5px;
  }

  .swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 0;
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none!important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
  line-height: 1;
  color: #b60000;
}

:root {
  --swiper-theme-color: #b60000;
}