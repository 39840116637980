$theme-colors: (
  "primary": #191e31,
  "secondary": #b60000,
  "info": #192245,
);

// Required
@import "../node_modules/bootstrap/scss/functions";

// Default variable overrides
$primary: #121627 !default;
$secondary: #b60000 !default;
$info: #192245 !default;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "info": $info,
);

// Required
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

body {
  background-color: $primary;
  color: white;
}

.bg-primary,
.btn-primary {
  background-color: $primary !important;
  color: white;
}

.text-primary {
  color: $primary !important;
}

.bg-secondary,
.btn-secondary {
  background-color: $secondary !important;
  color: white;
}

.btn {
  border-radius: 10px !important;
  box-shadow: 0 0 1rem 0px rgba(0, 0, 0, 0.425) !important;
}

.card {
  background-color: $info !important;
  border-radius: 20px !important;
  box-shadow: 0 0 5rem 0px rgba(0, 0, 0, 0.3) !important;
}

.btn-secondary {
  border: 1px solid $secondary;
}

.text-secondary {
  color: $secondary !important;
}

.bg-info,
.btn-info {
  background-color: $info !important;
  color: white;
}

.text-info {
  color: $info !important;
}

.modal-content {
  background-color: $primary;
}
.btn-link {
  border: none;
  background-color: transparent;
  color: $secondary;
  text-decoration: none;
}

.smooth-transition {
  transition: all 0.8s ease;
}
.on-hover:hover {
  background-color: $secondary !important;
}

.on-hover-primary:hover {
  color: $primary !important;
}

.on-hover-secondary:hover {
  transition: all 0.3s ease;
  color: $secondary !important;
}

.on-hover-light:hover {
  color: white !important;
}

.on-hover-scale:hover {
  transition: all 0.3s ease;
  transform: scale(0.97);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.712) !important;
}

.min-vh-75 {
  min-height: 75vh;
}

.cursor-pointer {
  cursor: pointer !important;
}

.btn-whatsapp {
  color: white;
  background-color: #25d366;
}
